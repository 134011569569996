

class Data {

constructor(ping){
  this.ping = ping;
}
getItems(){
          var items = [ {name: 'Delectrum', slug: 'delectrum', image: 'https://ch-art-files.s3.amazonaws.com/delecturn.png'},
          {name: 'Space Problem', slug: 'space-problem', image: 'https://ch-art-files.s3.amazonaws.com/space_problem.jpg'},
          {name: 'Biggy', slug: 'biggy', image: 'https://ch-art-files.s3.amazonaws.com/biggy.JPG'},
          {name: 'Roman Fire', slug: 'roman-fire', image: 'https://ch-art-files.s3.amazonaws.com/Roman Fire.jpg'},
          {name: 'IHOP', slug: 'ihop', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5182.jpeg'},
          {name: '1992', slug: '1992', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5190.jpg'},
          {name: 'Full Pull', slug: 'full-pull', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5205.jpg'},
          {name: 'логистика', slug: 'logistica', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5440.jpg'},
          {name: 'GE', slug: 'ge', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5219.jpg'},
          {name: 'Submit', slug: 'submit', image: 'https://ch-art-files.s3.amazonaws.com/IMG_5466.jpg'},
          {name: 'WIP', slug: 'wip',  image: 'https://ch-art-files.s3.amazonaws.com/IMG-5751.jpg'},
          {name: 'Hoss', slug: 'hoss',  image: 'https://ch-art-files.s3.amazonaws.com/necktie.jpg'},
          {name: 'Snow Bird', slug: 'snow-bird',  image: 'https://ch-art-files.s3.amazonaws.com/snowbirds.png'},
          {name: 'Toronto', slug: 'toronto',  image: 'https://ch-art-files.s3.amazonaws.com/tiger.jpg'},
          {name: 'Auto Shop', slug: 'auto-shop',  image: 'https://ch-art-files.s3.amazonaws.com/autoshop.png'}
         ];
        return items;
      }

getArtwork(slug){
        let items = this.getItems();
        for (var i = 0, l = items.length; i < l; i++) {
            var obj = items[i];
            if(obj.slug === slug){
              return [obj.name, obj.image];
            }
           }
           return [null, null];
    }

}

export default Data;
