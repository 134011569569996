import React from "react";
import ItemCard from "./ItemCard";
import "../index.css";

class BirdList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoaded: true,
    });
  }

  render() {
    const { error, isLoaded} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else if (this.props.items.length > 0){
      return (
        <div class="list-container">
        {this.props.items.map(item => (
          <ItemCard name={item.name} key={item.name} slug={item.slug} mainpic={item.image} />
          ))}
        </div>
      );
    }
    return null;
  }
}

export default BirdList;
