import React from "react";
import { NavLink} from "react-router-dom";
import "../index.css";

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Name: props.name,
      mainpic: props.mainpic,
      slug: props.slug
    };
  }

  render() {
    const { Name, mainpic, slug} = this.state;
    const artwork_path = '/artwork/'+slug;
      return (
        <div class="item-card">
        <div class="item-card-item-name">{Name}</div>
        <div class="item-image-wrapper">
            <img src={mainpic} class="item-image" />
        </div>
        <div class="item-card-footer">
         <NavLink to={artwork_path}><button>view</button></NavLink>
        </div>
        </div>
      );
  }
}
export default ItemCard;
