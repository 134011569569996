import React from "react";
import "../index.css";
import  AudioPlayer2  from "./AudioPlayer2.js";

class Music extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPlaying: '',
    };
    this.onTrackChange.bind(this);
  }

  onTrackChange = (event) => {
    //alert(event.target.id);
         this.setState({ isPlaying: event.target.id });
         this.refs.audio_player.setUrl(event.target.id, event.target.innerHTML);
        }



  handleLogin(event){
    var t = document.getElementById("fishtaco");
    if (t.value === 'pantherattack'){
      window.location.href = "/pantherCave";
    }else{
    alert('not authed');
  }
  t.value = '';
  }

  render(){
    return(
      <div class="music-box">
        <div class="music-box-item-right-info">

<div class="msection">
  <div class="section-title">West Liberty Panthers 2020 - Present</div>
  <div class="band-members">Chad Hurley, Toby Myrick, Jonathan Moore, Evan Faassen</div>
</div>
<div class="music-banner">
 <div class="album-left">
  <img src="/WLP.png" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">West Liberty Panthers - Studio Album - Coming 2022!</div>
 <div>
      <input type="text" id="fishtaco" size="20" />
      <button onClick={this.handleLogin}>Panther Login</button>
 </div>
    </div>
</div>
<div class="msection">
  <div class="section-title">Solo Albums 2008 - Present</div>
</div>

<div class="album">
 <div class="album-left">
  <img src="/thecove.png" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">The Cove</div>
  <div class="subtitle">2019</div>
   <div class="album-links">
       <div class="subtitle">Listen on:</div>
       <div class="streaming-link">
         <a href="https://music.apple.com/us/album/the-cove/1470559303">
           <img src="/applemusic.png" class="mlink-img"/>
         </a>
         <a href="https://open.spotify.com/album/3V6FmPfLAOgYcOS6CwQD1l">
            <img src="/spotify.png" class="mlink-img"/>
         </a>
         <a href="https://music.youtube.com/playlist?list=OLAK5uy_nM5cNH9gP-MU_X5w9C0SUri14bFvcIyKw">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
 <div class="clear"></div>
</div>


<div class="album">
 <div class="album-left">
  <img src="/anoldsystem.png" class="album_cover" alt="An Old System"/>
 </div>
 <div class="album-right">
 <div class="album-title">An Old System</div>
  <div class="subtitle">2017</div>
   <div class="album-links">
       <div class="subtitle">Listen on:</div>
       <div class="streaming-link">
         <a href="https://music.apple.com/us/album/an-old-system/1330061921">
           <img src="/applemusic.png" class="mlink-img"/>
         </a>
         <a href="https://open.spotify.com/album/5CoE4ZNUCnTDH4y62hONbT">
            <img src="/spotify.png" class="mlink-img"/>
         </a>
         <a href="https://music.youtube.com/playlist?list=OLAK5uy_kQvQK02gyB9EV1SbQ6lFSxBxTR0C85S2M">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
 <div class="clear"></div>
</div>



<div class="album">
 <div class="album-left">
  <img src="/theagamemnontapes.png" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">Agamemnon Tapes</div>
  <div class="subtitle">2010</div>
   <div class="album-links">
       <div class="subtitle">Listen on:</div>
       <div class="streaming-link">
         <a href="http://jamen.do/l/80769">
           <img src="/jamendo.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
 <div class="clear"></div>
</div>

<iframe width="48%" height="266" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/807093529&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div class="soundcloud_embed">
<a href="https://soundcloud.com/user-161550018" title="Chad Hurley" target="_blank" class="soundcloud_embed2">Chad Hurley</a> · <a href="https://soundcloud.com/user-161550018/brave-captain" title="Brave Captain - 4-25-2020" target="_blank" class="soundcloud_embed2">Brave Captain - 4-25-2020</a></div>
<div class="msection">
  <div class="section-title">China Town Kites - 2021</div>
</div>
        <iframe width="98%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1122949555&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe><div class="soundcloud_embed">
        <a href="https://soundcloud.com/user-161550018" title="Chad Hurley" target="_blank" class="soundcloud_embed2">Chad Hurley</a> · <a href="https://soundcloud.com/user-161550018/think-through-it" title="Think Through It" target="_blank" class="soundcloud_embed2">Think Through It</a></div>

        <div class="msection">
        <div class="section-title">XXB12</div>
        <div class="band-members"></div>
        </div>


        <div class="album-ext">
         <div class="album-left-ext">
          <img src="/universal.png" class="album_cover" />
         </div>
         <div class="album-right-ext">
         <div class="album-title">Universal - 2017</div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/01-melaxin.mp3" href="#" onClick={this.onTrackChange}>01-melaxin</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/02-plurent.mp3" href="#" onClick={this.onTrackChange}>02-plurent</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/03-balsham.mp3" href="#" onClick={this.onTrackChange}>03-balshamv</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/04-carmichael.mp3" href="#" onClick={this.onTrackChange}>04-carmichael</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/05-gergen.mp3" href="#" onClick={this.onTrackChange}>05-gergen</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/06-cloudhumper.mp3" href="#" onClick={this.onTrackChange}>06-cloudhumper</span></div>
          <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/07-glandoie.mp3" href="#" onClick={this.onTrackChange}>07-glandoie</span></div>
          <div class="clear"></div>
         </div>

         <div class="album-right-ext">
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/08-gloouity.mp3" href="#" onClick={this.onTrackChange}>08-gloouity</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/09-gosh.mp3" href="#" onClick={this.onTrackChange}>09-gosh</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/10-kerpitch.mp3" href="#" onClick={this.onTrackChange}>10-kerpitch</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/11-limsky.mp3" href="#" onClick={this.onTrackChange}>11-limsky</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/12-plankhouse.mp3" href="#" onClick={this.onTrackChange}>12-plankhouse</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/13-toiutou.mp3" href="#" onClick={this.onTrackChange}>13-toiutou</span></div>
                <div class="music-file-link-small"><img class="song-small" src="/speaker.png" /><span class="track-select" id="/music/xxb12/14-toushin.mp3" href="#" onClick={this.onTrackChange}>14-toushin</span></div>
                  <div class="clear"></div>

                  <AudioPlayer2 ref="audio_player" url="" title="" />
         </div>

        <div class="clear"></div>
        </div>


<div class="msection">
<div class="section-title">Gladys 1995 - 1999</div>
<div class="band-members">Chad Hurley, Brian Taylor, David Butler, Toby Myrick, Doug Schatz, Mike McCarty, Jason Russo, Brian Arnett, Bill Meadows</div>
</div>



<div class="album">
 <div class="album-left">
  <img src="/asmallseason.png" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">A Small Season</div>
  <div class="subtitle">1999</div>
 <div class="subtitle">Coolidge Records <a href="http://coolidgerecords.com/Coolidge-Artists/Gladys-A-Small-Season.html">CR0033</a></div>
   <div class="album-links">
       <div class="streaming-link">
         <a href="https://music.apple.com/us/album/a-small-season/134564744">
           <img src="/applemusic.png" class="mlink-img"/>
         </a>
         <a href="https://open.spotify.com/album/3SRh7UdCcB8qge2cBhiC4T">
            <img src="/spotify.png" class="mlink-img"/>
         </a>
         <a href="https://music.youtube.com/playlist?list=OLAK5uy_nEabogjH18LqJjmODpFYuWou-kKytUBIU">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
</div>


<div class="album">
 <div class="album-left">
  <img src="/gladysLuckylg.jpg" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">Lucky</div>
  <div class="subtitle">1997</div>
 <div class="subtitle">Coolidge Records <a href="http://coolidgerecords.com/Coolidge-Artists/Gladys-Lucky.html">CR0025</a></div>
   <div class="album-links">
       <div class="streaming-link">
         <a href="https://music.apple.com/us/album/lucky/134566694">
           <img src="/applemusic.png" class="mlink-img"/>
         </a>
         <a href="https://open.spotify.com/album/4fR8yravscR87ESyVZ6rNE">
            <img src="/spotify.png" class="mlink-img"/>
         </a>
         <a href="https://music.youtube.com/playlist?list=OLAK5uy_lIiGrJOfqU5aDEl1bgBLrpfsPoi8D_cpc">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
</div>




<div class="album">
 <div class="album-left">
  <img src="/coolidge50.jpg" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">Coolidge 50</div>
  <div class="subtitle">1998</div>
 <div class="subtitle">Coolidge Records <a href="http://coolidgerecords.com/Coolidge-Artists/Coolidge-50.html">CR028</a></div>
   <div class="album-links">
       <div class="streaming-link">
         <a href="https://open.spotify.com/album/1tvK4qqWdZo7h530CY9Ozq">
            <img src="/spotify.png" class="mlink-img"/>
         </a>
         <a href="https://music.youtube.com/playlist?list=OLAK5uy_kN87zbqjVCPfsm7blMIQRUD-5AwprtZfY">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
</div>


<div class="album">
 <div class="album-left">
  <img src="/kudzu2.jpg" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">Revival, Vol. 2</div>
 <div class="subtitle">Kudzu & Hollerin' Contest</div>
 <div class="subtitle">Various Artists</div>
  <div class="subtitle">1997</div>
    <div class="album-links">
      <a href="https://www.discogs.com/release/8097037-Various-Revival-Vol-II-Kudzu-And-Hollerin-Contest">www.discogs.com</a>
   </div>
 </div>
</div>




<div class="msection">
  <div class="section-title">Stranglmartin 1995</div>
  <div class="band-members">David Butler, Martin Shearer, Chad Hurley</div>
</div>


<div class="album">
 <div class="album-left">
  <img src="/sake.png" class="album_cover" />
 </div>
 <div class="album-right">
 <div class="album-title">For the Sake of Argument</div>
  <div class="subtitle">1995</div>
 <div class="subtitle">Wrocklage Records</div>
   <div class="album-links">
       <div class="streaming-link">
         <a href="https://music.apple.com/us/artist/chad-hurley/1330061925">
           <img src="/applemusic.png" class="mlink-img"/>
         </a>

         <a href="https://music.youtube.com/channel/UCMEG-pKQJsVCbgdzZahayJA?feature=share">
           <img src="/youtubemusic.png" class="mlink-img"/>
         </a>
       </div>
   </div>
 </div>
</div>






                <div>

              </div>

        </div>
      </div>
    );
  }
}
 export default Music;
