import React from "react";

import AppBody from "./components/AppBody";
import "./index.css";

class App extends React.Component {



  renderAppBody(){
    return <AppBody />;
  }

  render(){
    return(
      <div class="main-app">
         {this.renderAppBody()}
      </div>
    );
  }
}

export default App;
