import React from "react";
import { NavLink} from "react-router-dom";
import CategoryChooser from "./CategoryChooser";

import "../index.css";

class Banner extends React.Component {
  constructor(props) {
      super(props);
      this.handleSectionChange = this.props.onSectionChange.bind(this);
    }

  render(){
    return(
      <div class="header"><div class="logo">Chad Hurley</div>
       <div class="header-chooser">
       <nav>
           <NavLink class="nav-link" to="/artwork">Visuals</NavLink>&spades;
           <NavLink class="nav-link" to="/music">Musicals</NavLink>&spades;
           <NavLink class="nav-link" to="/software">Softwares</NavLink>
        </nav>
       </div>
      </div>
    );
  }
}

export default Banner;
