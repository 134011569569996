import React from "react";
import "../index.css";

class CategoryChooser extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      isLoaded: true,
      items: []
    };
  }

  handleChange(e) {
     this.props.onCategoryChange(e.target.value);
   }

  componentDidMount() {
    fetch("https://4qws7dys8g.execute-api.us-east-1.amazonaws.com/dev/category")
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            items: result.body,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
  }

  render(){
    const { isLoaded, items} = this.state;
    return(
      <div>
      <select class="category-chooser" onChange={this.handleChange} >
      {items.map(item => (
        <option key={item}>{item}</option>
        ))}
        </select>
      </div>
    );
  }
}

export default CategoryChooser;
