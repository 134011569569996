import React from 'react';
const {Howl, Howler} = require('howler');

class AudioPlayer2 extends React.Component {
  constructor(props) {
    super(props);
    this.player = null;
    this.state = {
      url: props.url,
      title: props.title,
      cur_stream: null,
      plsyer: null
    };
    //this.stop.bind(this);
  }
  setUrl(url,title){
    if(this.state.player != null){
      this.state.player.stop();
    }
      let player = new Howl({
          src: [url],
          html5: true
      });

    let id = player.play();

    this.setState({url: url,
                   title: title,
                   cur_stream: id,
                   player: player
                 });
  }

  stop = (e) => {
    this.state.player.stop();
  }

  render() {
    const curstat = this.state.url;
    return (
      <div>
        <span>{this.state.title}</span>
        <button onClick={this.stop}>STOP</button>
      </div>
    );
  }
}

export default AudioPlayer2;
