import React from "react";
import "../index.css";

class ArtBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      image: ''
    };
  }


  render() {
      return (
        <div class="art_box">
          <div class="art-box-item-image-wrapper">
              <img src={this.props.image} class="art-box-image" />
          </div>
          <div class="art-box-item-right-info">{this.props.name}</div>
        </div>
      );
  }
}
export default ArtBox;
