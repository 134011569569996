import React from "react";
import "../index.css";

class PantherCave extends React.Component {



  handleLogin(event){
    var t = document.getElementById("fishtaco");
    if (t.value == 'pantherattck'){
      alert('authed');
    }else{
    alert('not authed');
  }
  t.value = '';
  }

  render(){
    return(
      <div class="music-box">
        <div class="music-box-item-right-info">

          <div class="msection">
            <div class="section-title"><img src="cassette.png" class="cassette-tape" />Practice Tapes</div>

          </div>
<div class="practice-tapes-column">
        <div class="practice-tape" style={{
        backgroundImage: 'url("tweed2.png")',backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover'
        }}>
           <div class="section-title">Wilmington 9/19/2021 </div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/is_my_head_red_2021-09-19.mp3" target="_blank">is_my_head_red</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/her_house.mp3" target="_blank">her_house</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/whys_money_gotta_be_2021-09-19.mp3" target="_blank">whys_money_gotta_be</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/the_castle_2021-09-19.mp3" target="_blank">the_castle</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/power_supply_2021-09-19.mp3" target="_blank">power_supply</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/earlyriser2_2021-09-19.mp3" target="_blank">earlyriser2</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/in_the_middle_fragment_2021-09-19.mp3" target="_blank">in_the_middle_fragment</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/golden_city_2021-09-19.mp3" target="_blank">golden_city</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/it_takes_what_it_takes_2021-09-19.mp3" target="_blank">it_takes_what_it_takes</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/early_riser_2021-09-19.mp3" target="_blank">early_riser</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/dareisay.mp3" target="_blank">dareisay</a></div>
           <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/wilmington-2021-09-19/ramrod_2021-09-19.mp3" target="_blank">ramrod</a></div>
        </div>


    <div class="practice-tape" style={{ backgroundImage: 'url("silverclothe.png")'}}>
      <div class="section-title-black">Schenectady 10/17/2021 </div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/is_my_head_red_(redrum_red_version).mp3" target="_blank">is_my_head_red_(redrum_red_version)</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/the_middle.mp3" target="_blank">the_middle</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/whys_money_gotta_be.mp3" target="_blank">whys_money_gotta_be</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/whys_money_gotta_be_(long_intro).mp3" target="_blank">whys_money_gotta_be_(long_intro)</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/bell_court_blues.mp3" target="_blank">bell_court_blues</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/it_takes_what_it_takes.mp3" target="_blank">it_takes_what_it_takes</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/power_supply.mp3" target="_blank">power_supply</a></div>
        <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/schenectady-2021-10-17/jonny.mp3" target="_blank">jonny</a></div>
      </div>

      <div class="practice-tape" style={{
      backgroundImage: 'url("tweed2.png")',backgroundRepeat: 'no-repeat',
       backgroundSize: 'cover'
      }}>

    <div class="section-title">Greenville 10/03/2021</div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/golden_city_-_greenville_-_10-03-2021.mp3" target="_blank">golden_city</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/dare_i_say_(shuffle)_-_greenville_-_10-03-2021.mp3" target="_blank">dare_i_say_(shuffle)</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/ramrod_-_greenville_-_10-03-2021.mp3" target="_blank">ramrod</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/power_supply_-_greenville_-_10-03-2021.mp3" target="_blank">power_supply</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/her_house_-_greenville_-_10-03-2021.mp3" target="_blank">her_house</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/dare_i_say_(boot_scoot)_-_greenville_-_10-03-2021.mp3" target="_blank">dare_i_say_(boot_scoot)</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/the_middle_-_greenville_-_10-03-2021.mp3" target="_blank">the_middle</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/shanty_town_-_greenville_-_10-03-2021.mp3" target="_blank">shanty_town</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/the_castle_-_greenville_-_10-03-2021.mp3" target="_blank">the_castle</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/jonny_-_greenville_-_10-03-2021.mp3" target="_blank">jonny</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/is_my_head_red_-_greenville_-_10-03-2021.mp3" target="_blank">is_my_head_red</a></div>
      <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/greenville-2021-10-03/bo_diddley_experimentation_zone_-_greenville_-_10-03-2021.mp3" target="_blank">bo_diddley_experimentation_zone</a></div>
      <div class="music-file-link"><img class="zippy" src="zip.png" /><a href="/music/greenville-2021-10-03/greenville-2021-10-03.zip">greenville-2021-10-03.zip</a></div>
    </div>
</div>
<div class="practice-tapes-column">
    <div class="practice-tape" style={{backgroundImage: 'url("silverclothe.png")'}}>
      <div class="section-title-black">Larue Co. 9/26/2021</div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/the_middle.mp3" target="_blank">the_middle</a></div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/whys_money_gotta_be.mp3" target="_blank">whys_money_gotta_be</a></div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/power_supply.mp3" target="_blank">power_supply</a></div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/pipeline.mp3" target="_blank">pipeline</a></div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/the_castle_1.mp3" target="_blank">the_castle_1</a></div>
      <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/larueco-2021-09-26/the_castle.mp3" target="_blank">the_castle</a></div>
    </div>

  <div class="practice-tape" style={{
  backgroundImage: 'url("tweed2.png")',backgroundRepeat: 'no-repeat',
   backgroundSize: 'cover'
  }}>
    <div class="section-title">Winston-Salem 10/24/2021</div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/early_riser.mp3" target="_blank">early_riser</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/the_middle.mp3" target="_blank">the_middle</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/whys_money_gotta_be.mp3" target="_blank">whys_money_gotta_be</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/a_story.mp3" target="_blank">a_story</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/hey_bo_diddley.mp3" target="_blank">hey_bo_diddley</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/ramrod.mp3" target="_blank">ramrod</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/outskirts_of_town.mp3" target="_blank">outskirts_of_town</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/blues_#47.mp3" target="_blank">blues_#47</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/golden_city.mp3" target="_blank">golden_city</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/jonny.mp3" target="_blank">jonny</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/dave_song.mp3" target="_blank">dave_song</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/the_castle.mp3" target="_blank">the_castle</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/the_thrill_is_gone.mp3" target="_blank">the_thrill_is_gone</a></div>
    <div class="music-file-link"><img class="song" src="speaker.png" /><a href="/music/winston-salem-2021-10-24/cops_are_dumb_(north_korea).mp3" target="_blank">cops_are_dumb_(north_korea)</a></div>
  </div>

  <div class="practice-tape" style={{backgroundImage: 'url("silverclothe.png")'}}>
    <div class="section-title-black">Singles</div>
    <div class="music-file-link-black"><img class="song" src="speaker.png" /><a href="/music/one-offs/the_everybody_song.mp3" target="_blank">the_everybody_song</a></div>
  </div>

</div>




          </div>
        </div>






    );
  }
}
 export default PantherCave;
