import React from "react";
import Banner from "./Banner";
import BirdList from "./BirdList";
import ArtBox from "./ArtBox";
import Music from "./Music";
import PantherCave from "./PantherCave";
import CategoryChooser from "./CategoryChooser";
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import "../index.css";
import Data from "../data";


const url = "https://4qws7dys8g.execute-api.us-east-1.amazonaws.com/dev/items/"
const batch_size = "/100";
const xdata = new Data(4);
class AppBody extends React.Component {

  constructor(props) {
      super(props);
      this.artBoxElement = React.createRef();
      this.handleSectionChange = this.handleSectionChange.bind(this);
      this.state = {
                        mode: null,
                        category: '',
                        error: null,
                        items: xdata.getItems()
                    };
      }




    handleSectionChange(e){
      this.setState({mode: e.target.id});
    }
    renderBanner(){
      return <Banner onSectionChange={this.handleSectionChange}/>;
    }
    renderMusic(){
      return <Music />;
    }
    renderPantherCave(){
      return <PantherCave />
    }
    resolveArtwork(){
      var thePath = window.location.href;
      var slug = thePath.substring(thePath.lastIndexOf('/') + 1);
      return xdata.getArtwork(slug);
      }

      renderArtBox(){
        let res = this.resolveArtwork();
        let image = res[1];
        let name = res[0];
        return <ArtBox name={name} image={image} />;
      }

  render(){

    return(
      <BrowserRouter>
      <div class="abody" style={{
      backgroundImage: 'url("https://ch-art-files.s3.amazonaws.com/biggy.JPG")',
      opacity: .9
    }}>
      {this.renderBanner()}
      <div class="body-right list-container">
       <Switch>
          <Route exact path="/artwork">
            <BirdList items={this.state.items} />
          </Route>
          <Route path="/artwork/:art_id" render={() => this.renderArtBox()} />
          <Route exact path="/music" render={() => this.renderMusic()} />
          <Route exact path="/PantherCave" render={() => this.renderPantherCave()} />

        </Switch>
      </div>
      </div>
      </BrowserRouter>
    );
  }
}


export default AppBody;
